<template>
  <AdminTemplate v-loading="pageLoad">
    <div v-if="countDown" class="countLoading">
      <div class="text-center">
        <h1 class="text-primary font-64">รอเฉลย...</h1>
        <div class="cicle-count mg-x-auto mg-t-1" style="font-size: 104px">
          {{ countTime }}
        </div>
      </div>
    </div>
    <el-col :span="18" :offset="3" v-else>
      <h1 class="text-center mg-y-5">{{ exam.question }}</h1>
      <div class="rank-score">
        <el-row class="h-100">
          <el-col :offset="3" :span="6" class="h-100">
            <div
              class="
                pd-x-1
                h-100
                is-flex
                ai-end
                flex-warp
                js-center
                text-primary text-center
                ai-content-end
              "
            >
              <div class="w-100 graph-size">
                <i
                  class="el-icon-success"
                  style="font-size: 44px"
                  v-if="exam.answer == 1 && answer"
                ></i>
                {{ userAnswer.one.length }}
              </div>
              <div
                class="score bg-primary w-100"
                :class="{ 'cr-pointer': answer }"
                :style="
                  (userAnswer.one.length * 100) / total > 75
                    ? 'height:' + 55 + '%'
                    : (userAnswer.one.length * 100) / total > 0
                    ? 'height:' + (userAnswer.one.length * 100) / total + '%'
                    : 'height:2%'
                "
              />
              <div>
                <p class="mg-b-0 mg-t-6 text-center text-grey font-28">
                  ข้อที่ 1
                </p>
                <p class="mg-y-0 text-primary font-32 cr-pointer m-h-35">
                  <span
                    v-show="
                      answer &&
                      typeof exam.picAnswer != 'undefined' &&
                      exam.picAnswer.q1 != ''
                    "
                    @click="answer ? openModal('q1') : ''"
                    >ดูคำอธิบาย</span
                  >
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="6" class="h-100">
            <div
              class="
                pd-x-1
                h-100
                is-flex
                ai-end
                flex-warp
                js-center
                text-danger text-center
                ai-content-end
              "
            >
              <div class="w-100 graph-size">
                <i
                  class="el-icon-success"
                  style="font-size: 44px"
                  v-if="exam.answer == 2 && answer"
                ></i>
                {{ userAnswer.two.length }}
              </div>
              <div
                class="score bg-danger w-100"
                :class="{ 'cr-pointer': answer }"
                :style="
                  (userAnswer.two.length * 100) / total > 75
                    ? 'height:' + 55 + '%'
                    : (userAnswer.two.length * 100) / total > 0
                    ? 'height:' + (userAnswer.two.length * 100) / total + '%'
                    : 'height:2%'
                "
              />
              <div>
                <p class="mg-b-0 mg-t-6 text-center text-grey font-28">
                  ข้อที่ 2
                </p>
                <p class="mg-y-0 text-primary font-28 cr-pointer m-h-35">
                  <span
                    v-show="
                      answer &&
                      typeof exam.picAnswer != 'undefined' &&
                      exam.picAnswer.q2 != ''
                    "
                    @click="answer ? openModal('q2') : ''"
                    >ดูคำอธิบาย</span
                  >
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="6" class="h-100">
            <div
              class="
                pd-x-1
                h-100
                is-flex
                ai-end
                flex-warp
                js-center
                text-warning text-center
                ai-content-end
              "
            >
              <div class="w-100 graph-size">
                <i
                  class="el-icon-success"
                  style="font-size: 44px"
                  v-if="exam.answer == 3 && answer"
                ></i>
                {{ userAnswer.three.length }}
              </div>
              <div
                class="score bg-warning w-100"
                :class="{ 'cr-pointer': answer }"
                :style="
                  (userAnswer.three.length * 100) / total > 75
                    ? 'height:' + 55 + '%'
                    : (userAnswer.three.length * 100) / total > 0
                    ? 'height:' + (userAnswer.three.length * 100) / total + '%'
                    : 'height:2%'
                "
              />
              <div>
                <p class="mg-b-0 mg-t-6 text-center text-grey font-28">
                  ข้อที่ 3
                </p>
                <p class="mg-y-0 text-primary font-28 cr-pointer m-h-35">
                  <span
                    v-show="
                      answer &&
                      typeof exam.picAnswer != 'undefined' &&
                      exam.picAnswer.q3 != ''
                    "
                    @click="answer ? openModal('q3') : ''"
                    >ดูคำอธิบาย</span
                  >
                </p>
              </div>
            </div>
          </el-col>
          <el-dialog :visible.sync="modalAnswer" width="65%" top="5vh">
            <div>
              <img
                v-if="imgAnswer"
                class="mw-100"
                :src="
                  require('@/assets/exam/picture/' + exam.picAnswer[imgAnswer])
                "
                alt=""
              />
            </div>
          </el-dialog>
        </el-row>
      </div>
      <el-col :span="12" :offset="6">
        <el-button
          type="primary"
          class="w-100 mg-t-3 mg-b-5"
          @click="answer == false ? countdown() : showRank()"
          >{{ answer ? "สรุปคะแนน" : "เฉลย" }}</el-button
        >
      </el-col>
    </el-col>
  </AdminTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import AdminTemplate from "@/template/VoteAdminTemplate";
export default {
  components: {
    AdminTemplate
  },
  data() {
    return {
      total: 0,
      countTime: 5,
      countDown: false,
      pageLoad: false,
      userAnswer: {
        one: [],
        two: [],
        three: [],
        total: 0
      },
      answer: false,
      imgAnswer: "",
      score: {
        q1: 3,
        q2: 8,
        q3: 4
      },
      modalAnswer: false
    };
  },
  mounted() {
    this.getUserAnswer();
    this.intervalation = setInterval(() => {
      this.getUserAnswer();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalation);
  },
  methods: {
    countdown() {
      this.countDown = true;
      if (this.countTime == 5) {
        this.showAnswer();
      }

      if (this.countTime <= 0) {
        this.countDown = false;
        return false;
      }
      setTimeout(() => {
        setTimeout(() => {
          this.countTime--;
          this.countdown();
        }, 900);
      }, 500);
    },
    getUserAnswer ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("/get/answer", {
        roomId: this.room.roomId,
        qId: this.step.qId
      })
        .then(res => {
          // console.log("/get/answer", res);
          if (res.data.success) {
            this.userAnswer.one = res.data.obj.one;
            this.userAnswer.two = res.data.obj.two;
            this.userAnswer.three = res.data.obj.three;
            this.total =
              res.data.obj.three.length +
              res.data.obj.two.length +
              res.data.obj.one.length;
          }
        })
        .finally(() => {});
    },
    async showRank() {
      this.answer = true;
      let obj = {
        roomId: this.room.roomId,
        qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid,
        attId: this.step.attId,
        total: true,
        state: "rank"
      };
      await this.setState(obj);
      this.$router.push("/rank-score");
    },
    showAnswer() {
      this.answer = true;
      let obj = {
        roomId: this.room.roomId,
        qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid,
        attId: this.step.attId,
        total: true,
        state: "answer"
      };
      this.setState(obj);
    },
    openModal(index) {
      // console.log("saddsasda", this.exam.picAnswer[index], index);
      this.modalAnswer = true;
      this.imgAnswer = index;
    }
  },
  computed: {
    allExam() {
      return this.$store.state.exam;
    },
    exam() {
      let data = this.$store.state.exam.filter(a => a.no == this.step.qId);
      // console.log("exam", data);
      if (data.length > 0) {
        return data[0];
      } else {
        return "";
      }
    }
  }
};
</script>
<style scoped>
</style>
